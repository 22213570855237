import { SITE_TITLE } from "../../utils/consts";
import DesktopNav from "../nav/desktop/desktop";
import "./header.scss";

const Header: React.FC = () => {
    return (
        <header>
            <div className="hero-wrapper">
                <div className="hdr-wrapper">
                    <a
                        href="/feed"
                        style={{ position: "relative", zIndex: "20" }}
                    >
                        <img
                            className="siren-logo"
                            src="/images/siren-intro-logo.svg"
                            alt="Siren"
                        />
                    </a>
                    <DesktopNav />
                </div>
            </div>
        </header>
    );
};

export default Header;
