import { useEffect, useState } from "react";
import "./creds.scss";

interface CredsProps {
    name: string;    
}


const Creds = () => {
    const [creds, setCreds] = useState<CredsProps[]>([]);

    useEffect(() => {
        const scanCredsFolder = async () => {
            try {
            const notes = '/keynote/folders.txt';
            const response = await fetch(notes);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const text = await response.text();
            console.log("text", text);
            const rows = text.split('\n').map(row => {
                const name = row.trim();
                return { name };
            });
            setCreds(rows);

            } catch (error) {
            console.error("Error scanning creds folder:", error);
            }
        };

        scanCredsFolder();
        
    }, [creds]);

    return (
        <div className="creds-one">
            <h1>Creds</h1>
            {creds.map((cred, index) => (
                <div key={index} className="cred">
                    <h2>{cred.name}</h2>
                    <a href={`keynote/${cred.name}`}>
                        <p>
                            {`sirendev.co.uk/keynote/${cred.name}`}
                        </p>
                    </a>
                    
                    
                </div>
            ))}
        </div>
    );
};

export default Creds;
